import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import animationData from "../../../assets/business-team.json";
import Lottie from "react-lottie";


const BlogPage = () => {
    const [blogs, setBlogs] = useState([]);

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get('/api/blogs');
        setBlogs(response.data);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);
 

  
  return (
    <section className="dark:text-gray-100  bg-gradient-to-r dark:from-black dark:via-black dark:to-gray-900 from-white via-white to-white min-h-screen transition-colors duration-300 ">
  <div className="container max-w-6xl p-6 mx-auto  space-y-6 sm:space-y-12 ">


 <div
  rel="noopener noreferrer"
  className="block max-w-sm mx-auto gap-3 mt-20 sm:max-w-full lg:grid lg:grid-cols-12 bg-gray-100 hover:no-underline focus:no-underline dark:bg-gray-900 dark:border-gray-700 dark:text-gray-100 shadow-lg hover:shadow-2xl rounded-3xl transition-shadow relative p-4"
>
  <div className="overflow-hidden rounded-3xl lg:col-span-7">
    <Lottie 
      options={defaultOptions}
      height={400} 
      width={400}
      className="object-cover w-full h-64 sm:h-96 dark:bg-gray-900 border-r"
    />
  </div>
  
  <div className="p-4 sm:p-6 space-y-2 lg:col-span-5 flex flex-col justify-between">
    <div>
      <h3 className="text-2xl font-semibold sm:text-4xl dark:text-blue">
        Ask Senior
      </h3>
      <span className="text-xs dark:text-gray-400">Jan, 2023</span>
      <p className="dark:text-gray-300 mt-2 sm:mt-4">
        Founded in mid-2023, AskSenior was born out of a mission to bridge the gap between experienced mentors and aspiring learners. We believe in fostering meaningful interactions that empower growth and knowledge. At AskSenior, it's not about writing blogs for the sake of followers—it's about sharing insights that truly matter and make a difference, especially in education. Our goal is to inspire, guide, and help learners unlock their full potential through genuine and impactful content.
      </p>
      <br></br>
      <a 
      href="https://www.linkedin.com/company/asksenior/mycompany/" 
      target="_blank" 
      rel="noopener noreferrer" 
      className="p-2 pr-7 pl-7 rounded bg-skyblue font-semibold text text-white hover:bg-teal-600 transition"
    >
      Follow
    </a>
    </div>
  </div>
  
  {/* Responsive footer for the bottom corner */}
 
</div>



    <div className="grid justify-center grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 ">
      {blogs.map((blog) => (
        <Link to={`/blog/${blog._id}`} style={{ textDecoration: 'none' }}
          rel="noopener noreferrer"
          href="#"
          className="max-w-sm mx-auto group bg-gray-100 rounded-xl hover:no-underline focus:no-underline dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700 transform transition-transform duration-300 hover:-translate-y-2 shadow-lg hover:shadow-2xl"
        >
          <div className="overflow-hidden rounded">
            <div className="w-full p-4 h-44">
              <img
                className="w-full h-full  object-cover transform transition-transform duration-500 ease-in-out group-hover:scale-105 dark:bg-gray-700"
                src={blog.image}
                alt="Blog Image"
              />
            </div>
          </div>
          <div className="p-6 space-y-2">
            <h3 className="text-2xl font-semibold group-focus:underline dark:text-gray-100">
              {blog.title}
            </h3>
            <span className="text-xs dark:text-gray-400">{new Date(blog.createdAt).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}</span>
            <p className="dark:text-gray-300">{blog.description}</p>
          </div>
        </Link>
      ))}
    </div>

    <div className="flex justify-center">
      <button
        type="button"
        className="px-6 py-3 text-sm rounded-md hover:underline bg-gray-100 dark:bg-gray-800 dark:text-gray-100 dark:border-gray-700"
      >
        Load more posts...
      </button>
      
    </div>
  </div>
 
</section>

  )
}

export default BlogPage
